import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

initializeIcons()

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

root.render(
  <>
    <FluentProvider theme={webLightTheme}>
      <App />
    </FluentProvider>
  </>
);


reportWebVitals();
